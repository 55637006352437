import React from 'react';
import './App.css';
import 'bulma/css/bulma.css';

import ProjectBox from './components/project-box'

import img from './img/wmcp.png'
import github from './img/github.png'


function App() {
  return (
    <div>
      <section class="hero is-success is-fullheight has-text-centered">
        <div class="hero-body">
          <div class="container">
            <h1 class="title is-size-1">
              Jamie Shears
            </h1>
            <h5 class="subtitle has-text-weight-light">
              Hi
            </h5>
            <a href="https://github.com/jsws">
              <img alt="github" src={github} />
            </a>
          </div>
        </div>
      </section>
      <section class="section">
        <div class="container has-text-centered">
          <h1 class="title">
            Projects
           </h1>
          <p class="subtitle">
            Here's some stuff I've <strong>built</strong>!
          </p>
          <ProjectBox
            title="Will My Car Pass?"
            img={img}
            year="2019"
            link="https://willmycarpass.co.uk"
            description="A website that allows people to search for their car and see its MOT statistics. Users can search via
                        their registration number or make and model. Data was analysied from 22 million entries of publicly available
                        data."
            tags={["ReactJS",
              "Go",
              "Pandas",
              "Python",
              "Big data"]}
          />
        </div>
      </section>
    </div>
  );
}

export default App;
