import React from 'react';

class ProjectBox extends React.Component {
    render() {
        
        const listTags = this.props.tags.map((tag) =>
            <span class="tag is-success is-light">{tag}</span>
        );

        return (
            <div class="box">
                <article class="media">
                    <div class="columns">
                        <div class="column is-one-half">
                            <figure class="image">
                                <a href={this.props.link} target="_blank" rel="noopener noreferrer"><img alt="project screenshot" src={this.props.img} /></a>
                            </figure>
                        </div>
                        <div class="column is-one-half">
                            <div class="content">
                                <p>
                                    <a href={this.props.link} target="_blank" rel="noopener noreferrer"><strong>{this.props.title}</strong></a>
                                    <br />
                                    <small>{this.props.year}</small>
                                    <br /><br />
                                    <p>
                                        {this.props.description}
                                    </p>
                                    <div class="tags">
                                        {listTags}
                                    </div>
                                </p>
                            </div>
                        </div>
                    </div>
                </article>
            </div>

        );
    }
}

export default ProjectBox;